<template>  
<div class="themev2">
  <br>  
  <div class="logo-centered"></div>

  <div class="w560">

    <!-- <div class="w300" v-if="$store.state.auth.status == 'register_success'">
      <h1><span class="checkmark"></span>Registration sent</h1>
      <p>You will receive an email when your account is ready to use. This can take 1 - 2 days.</p>
    </div>

    <div class="w300" v-if="$store.state.auth.status != 'register_success'">
      <h1>Create new account</h1>
      <p>Fill in your details below and an administrator will review and activate your account</p>       
    </div> -->

    <!-- <div class="w300" v-if="$store.state.auth.status != 'register_success'">
      <h1>Registration sent. Check your email.</h1>
      <p>We've emailed you a link to <b>{{form.email}}</b> that you can use to confirm your email address.</p>       
    </div>  -->

    <div class="w300">
      <h1>Create new account</h1>
      <p>Fill in your details below {{$route.query.invite ? 'to accept the invitation' : 'and an administrator will review and activate your account'}}:</p>       
    </div>

    <!-- <ValidationObserver v-slot="{ invalid, handleSubmit}" tag="div" class="w300" v-if="!$store.state.auth.status.startsWith('register_success')"> -->
    <ValidationObserver v-slot="{ invalid, handleSubmit}" tag="div" class="w300">
      <form ref="form" method="POST" @submit.prevent="handleSubmit(onSubmit)" spellcheck="false" :class="{disabled: $store.getters.loading}">
        
        <ValidationProvider name="firstName" rules="required" tag="div" class="inputrow"  v-slot="{ classes, errors }">
          <label for="firstName">First name</label>
          <input v-model="form.firstName" name="firstName" type="text" novalidate autocomplete="off" :class="classes" :disabled="$store.getters.loading">          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="lastName" rules="required" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="lastName">Last name</label>
          <input v-model="form.lastName" name="lastName" type="text" novalidate autocomplete="off" :class="classes" :disabled="$store.getters.loading">          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="email" rules="required" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="email">Organizational Email</label>
          <input v-model.trim="form.email" name="email" type="email" novalidate :class="classes" :disabled="$store.getters.loading || $route.query.invite">          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="role" rules="required" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="email">Role</label>
          <select class="select-css" v-model="form.role" :class="classes" style="height: 30px;" :disabled="$store.getters.loading || $route.query.invite">
            <option value="Student" selected>Student</option>
            <option value="Professor">Faculty</option>
            <option value="OrgAdmin" v-show="$route.query.invite">Organization Admin</option>
            <option value="Admin" disabled v-show="$route.query.invite && form.role == 'Admin'">Admin</option>
          </select>
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="timezone" rules="required" tag="div" ref="timeZoneValidator" class="inputrow" v-slot="{ classes, errors }">
          <label for="timezone">Time zone</label>          
          <vSelect
              :disabled="$store.getters.loading" 
              :class="classes"
              placeholder="Select preferred time zone"
              name="timezone"
              style="width:100%; clear:both;"
              :options="timeZones" 
              @close="validateSelectedTimeZone"      
              :clearable="false"
              v-model="form.timeZone"  
              :reduce="tz => tz.id" 
              label="id"        
          >
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes"></span>
            </template>
            <template #option="tz">              
              {{tz.id}} (GMT{{tz.offset >= 0 ? '+':''}}{{tz.offset}})
            </template>
            <template #selected-option="tz">
              {{tz.id}} (GMT{{tz.offset >= 0 ? '+':''}}{{tz.offset}})
            </template>   
          </vSelect>
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="password" ref="password" rules="required|password" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="password">Password</label>
          <input v-model="form.password" name="password" type="password" novalidate :class="classes" placeholder="At least 8 characters and 1 number" :disabled="$store.getters.loading" />          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="password2" rules="required|confirmed:password" tag="div" mode="aggressive" class="inputrow" v-slot="{ classes, errors }">
          <label for="password2">Repeat password</label>
          <input v-model="form.password2" name="password2" type="password" novalidate :class="classes" placeholder="At least 8 characters and 1 number" :disabled="$store.getters.loading" />          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="phone" rules="phone" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="phone">Phone number <span style="font-size: 10px; color: grey; padding-left: 5px; vertical-align: bottom;">(optional)</span></label>
          <input v-model="form.phone" name="phone" type="tel" novalidate autocomplete="off" :class="classes" :disabled="$store.getters.loading">          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <br>        
        <button class="full" type="submit" :class="{disabled: invalid || $store.getters.loading}" :disabled="invalid || $store.getters.loading">Register</button>

        <span class="spinner" v-if="$store.getters.loading" />

        <div class="formerror" v-if="$store.state.auth.error">{{$store.state.auth.error}}</div>

      </form>
    </ValidationObserver>  
  </div>
</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {REGISTER_REQUEST} from "../store/actions/auth";
import '@/veevalidate.js'
import vSelect from 'vue-select'
import { getTimeZoneOffset } from '@/utils.js'


export default {
  name: 'Register',
  data: function(){
    return {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        role: 'Student',
        password: '',
        password2: '',
        phone: '',
        token: null,
        timeZone: ''
      }   
    }
  },
  computed: {    
    timeZones(){
      return Intl.supportedValuesOf('timeZone').map(tz => ({id: tz, offset: getTimeZoneOffset(tz)}))
    }
  },
  methods: {  
    validateSelectedTimeZone(){
      return this.$refs.timeZoneValidator.validate()
    },       
    async onSubmit() {  
      await this.$store.dispatch(REGISTER_REQUEST, this.form)           
    }  
  },
  components: {
    ValidationProvider, 
    ValidationObserver,
    vSelect
  },
  mounted(){
    if(this.$route.query.invite){
      let decoded = atob(this.$route.query.invite).split(';')    
      let invite = {}
      decoded.forEach(part => {
        let tmp = part.split('=')
        invite[tmp[0]] = tmp[1]
      });
      this.form.firstName = invite['firstName']?.trim()
      this.form.lastName = invite['lastName']?.trim()
      this.form.email = invite['email']?.trim()      
      if(invite['role'] && ['Professor','OrgAdmin','Admin'].includes(invite['role'])){
        console.log(invite['role'])
        this.form.role = invite['role']
      }
      this.form.token = invite['token']
    }
  },
}
</script>

<style lang="scss">

</style>